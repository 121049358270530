import { TFunction } from "react-i18next";
import {
	PaymentMethodDictionaryType,
	PaymentMethodType,
	RolesNumberEnum,
} from "src/@types";

export const services = (t: TFunction<"global", undefined>) => [
	{ id: 1, name: t("Services.Checkup", { ns: "global" }) },
	{ id: 2, name: t("Services.Follow_Up", { ns: "global" }) },
	{ id: 3, name: t("Services.Emergency", { ns: "global" }) },
];

export const paymentMethods = (
	t: TFunction<"global", undefined>
): PaymentMethodType[] => [
	{ id: 0, name: t("Payment_Methods.Cash", { ns: "global" }) },
	{ id: 1, name: t("Payment_Methods.Credit_Card", { ns: "global" }) },
];

export const paymentMethodsDictionary = (t: TFunction<"global", undefined>) =>
	paymentMethods(t).reduce(
		(acc, curr) => ({ ...acc, [curr.id]: curr }),
		{}
	) as PaymentMethodDictionaryType;

export const roles = (t: TFunction<"global", undefined>) => [
	{ id: RolesNumberEnum.ADMIN, name: t("Roles.Admin", { ns: "global" }) },
	{ id: RolesNumberEnum.DENTIST, name: t("Roles.Dentist", { ns: "global" }) },
	{
		id: RolesNumberEnum.ASSISTANT,
		name: t("Roles.Assistant", { ns: "global" }),
	},
];

export const roles_without_admin = (t: TFunction<"global", undefined>) => [
	{ id: RolesNumberEnum.DENTIST, name: t("Roles.Dentist", { ns: "global" }) },
	{
		id: RolesNumberEnum.ASSISTANT,
		name: t("Roles.Assistant", { ns: "global" }),
	},
];
