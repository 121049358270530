import {
	UserType,
	UserDictionaryType,
	UserRoleType,
	UserBasicInfoType,
} from "src/@types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	authenticate,
	blockOrUnBlockUser,
	fetchAllUsers,
	fetchUser,
} from "./authAsyncActions";
import { toast } from "react-toastify";

const emptyUser: UserType = {
	id: 0,
	name: "",
	phone: "",
	picture: "",
	email: "",
	role: 0,
	status: 0,
	tasks: 0,
	blocked: false,
	admin: {
		id: 0,
		workStarts: undefined,
		workEnds: undefined,
		clinicName: "",
		clinics: 0,
		clinicAddress: "",
		clinicNumber: "",
		phone: "",
		status: 0,
		subscriptionExpires: new Date().toISOString(),
		subscriptionType: 0,
	},
};

interface InitialState {
	loading: boolean;
	fetchingLoading: boolean;
	user: UserType;
	errorMsg: string;
	login: {
		userRole: UserRoleType | null;
		access_token: string;
		token_type: string;
		isLoggedIn: boolean;
		loginLoading: boolean;
		loginErrorMsg: string;
	};
	users: UserDictionaryType | null;
}

const initialState: InitialState = {
	loading: false,
	fetchingLoading: false,
	user: emptyUser,
	login: {
		isLoggedIn: localStorage.getItem("token") ? true : false,
		loginLoading: false,
		loginErrorMsg: "",
		userRole: localStorage.getItem("userRole") as UserRoleType,
		access_token: localStorage.getItem("token") ?? "",
		token_type: "",
	},
	users: null,
	errorMsg: "",
};

const userSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout: (state) => {
			state.login.isLoggedIn = false;
			state.login.userRole = null;
			state.login.access_token = "";
			state.login.token_type = "";
			state.user = emptyUser;
			localStorage.removeItem("token");
			localStorage.removeItem("userRole");
		},

		updateUser: (
			state,
			action: PayloadAction<
				Partial<Pick<UserType, "name" | "email" | "picture" | "tasks">>
			>
		) => {
			const { name, email, picture, tasks } = action.payload;

			if (name !== undefined) state.user.name = name;
			if (email !== undefined) state.user.email = email;
			if (picture !== undefined) state.user.picture = picture;
			if (tasks !== undefined) state.user.tasks = tasks;
		},

		updateClinicSettings: (
			state,
			action: PayloadAction<
				Partial<
					Pick<
						UserType["admin"],
						| "clinicName"
						| "clinics"
						| "clinicAddress"
						| "phone"
						| "workStarts"
						| "workEnds"
					>
				>
			>
		) => {
			const {
				clinicName,
				clinics,
				clinicAddress,
				phone,
				workEnds,
				workStarts,
			} = action.payload;

			if (clinicName) state.user.admin.clinicName = clinicName;
			if (clinics) state.user.admin.clinics = clinics;
			if (clinicAddress) state.user.admin.clinicAddress = clinicAddress;
			if (phone) state.user.admin.phone = phone;
			if (workStarts) state.user.admin.workStarts = workStarts;
			if (workEnds) state.user.admin.workEnds = workEnds;
		},

		updateUsers: (state, action: PayloadAction<UserBasicInfoType>) => {
			const new_user = action.payload;

			state.users = { ...state.users, [new_user.id]: new_user };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUser.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchUser.rejected, (state, action) => {
			state.errorMsg = action.payload || "";
			state.loading = false;
		});

		builder.addCase(fetchUser.fulfilled, (state, action) => {
			state.user = {
				...action.payload,
				admin: {
					...action.payload.admin,
					subscriptionExpires: new Date(
						action.payload.admin.subscriptionExpires
					).toISOString(),
				},
			};
			state.loading = false;
			state.errorMsg = "";
		});

		builder.addCase(fetchAllUsers.pending, (state) => {
			state.fetchingLoading = true;
		});

		builder.addCase(fetchAllUsers.rejected, (state, action) => {
			state.fetchingLoading = false;
			state.errorMsg = action.payload || "";
		});

		builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
			state.users = action.payload.reduce(
				(obj, user) => ({ ...obj, [user.id]: user }),
				{}
			);
			state.fetchingLoading = false;
		});

		builder.addCase(authenticate.pending, (state) => {
			state.login.loginLoading = true;
		});

		builder.addCase(authenticate.rejected, (state, action) => {
			state.login.loginErrorMsg = action.payload || "";
			state.login.loginLoading = false;
			state.login.isLoggedIn = false;
		});

		builder.addCase(authenticate.fulfilled, (state, action) => {
			const { access_token, token_type, userRole } = action.payload;

			state.login.isLoggedIn = true;
			state.login.access_token = access_token;
			state.login.token_type = token_type;
			state.login.userRole = userRole;
			state.login.loginLoading = false;
		});

		builder.addCase(blockOrUnBlockUser.rejected, (state, action) => {
			toast.error(action.payload || "");
		});

		builder.addCase(blockOrUnBlockUser.fulfilled, (state, action) => {
			if (state.users?.[action.payload.user.id]) {
				state.users[action.payload.user.id].blocked = action.payload.blocked;
			}
		});
	},
});

export const { logout, updateUser, updateClinicSettings, updateUsers } =
	userSlice.actions;

export const authReducer = userSlice.reducer;
